@import "v3/partials/variables";
@import "v3/partials/mixins";

#main {
    max-width: 1100px;
    padding-top: 40px;
}

.box {
    padding: 18px;
}

.box-head {
    small {
        float: right;
    }

    .site-content-hero &  {
        line-height: 36px;
    }
    .content-form &{
    	line-height:36px;
    	}
}

.box-condensed {
    padding: 0 18px;
}

.box-blue {
    color: white;
}

.box-hero {
    min-height: 555px;
    padding: $baseLineHeight * 1.5;
}

.box-hero-header {
    margin-top: ($baseLineHeight * 7.5);
    margin-bottom: $baseLineHeight * 1.5;
}

.box-hero-tailfin {
    @extend .box-hero;
    background: url(/content/images/chrome/rebrand/aa-tailfin.jpg) left top no-repeat;
    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;

    .btn-search{
		top: 0.3em;
	}
}

.box-hero-aircraft {
    @extend .box-hero;
    background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.59) 60.42%, rgba(255, 255, 255, 0) 100%), url(/content/images/chrome/rebrand/discontinued-page.jpg);
    background-repeat: no-repeat;
    background-position: left;
    min-height: 680px;

    .box-hero-header {
        margin-top: 100px;
        margin-bottom: 10px;
    }
    .sub-header {
        @include font-size(18);
        margin-bottom: 30px;
        color: $darkBlue;
    }
    .btn-search{
		top: 0.3em;
	}
	#btn-goto-home {
	    min-width: 80px;
	    height: 50px;
	    line-height: 50px;
	}
}

.not-found-subheader {
    margin-top: 40px;
}

.not-found-links a {
    text-decoration: underline;
}

.not-found-container {
    h1, .not-found-links a {
        color: $darkBlue;
    }
    color: $darkBlue;
    padding: 6.5%;
    min-height: 555px;
    background: url(/content/images/chrome/rebrand/AA-taken-flight.jpg) center 75% no-repeat;
    background-size: cover;
    font-family: $sansFontFamilyLight;
}

@include respond-to(tablet_down) {
    .container {
        padding-left: 0;
        padding-right: 0;
    }
}

@include respond-to(phone) {
    #main {
        padding-top: 0;
    }

    .box-hero-tailfin {
        background: none;
    }
    .box-hero {
        min-height: 300px;
    }
    .box-hero-header {
        margin-top: 0.3em;
        margin-bottom: 0.3em;
    }
    .box-hero-aircraft {
        min-height: 500px;
        background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.59) 60.42%, rgba(255, 255, 255, 0) 100%), url(/content/images/chrome/rebrand/discontinued-mobile-page.jpg);
        text-align: center;
        background-size: cover;
        background-position: center, center;

        .box-hero-header {
            margin-top: 50px;
            margin-bottom: 10px;
        }
        #btn-goto-home {
            width: 90px;
            height: 50px;
            line-height: 50px;
        }
    }

    header .container {
        margin-bottom: 0;
    }
    main .container {
        padding-left: 0;
        padding-right: 0;
    }
    body .not-found-container {
        padding-top: 13%;
        min-height: 555px;
        text-align: center;
        margin-top: 0;
        margin-bottom: -40px;
        margin-left: -3.2%;
        margin-right: -3.2%;

        .not-found-subheader {
            margin-top: 0px;
        }
    }
    footer {
        margin-top: 0;
    }
}
